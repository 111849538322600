<template>
    <div>
        <header-portrate />

    <div class="m-2" style="margin-bottom:70px !important;">
        <bread-crumbs :items="items"></bread-crumbs> 
        <tabs-comp :tabs="tabs" />
        
        <v-row :style="`direction:`+lang.dir">
            <v-col
            cols="12"
            md="5"
            sm="12">
            <b-input-group class="mt-3">
                <b-form-input placeholder="Start Date" style="background:#eee" type="date" v-model="sdate"></b-form-input>
                <b-form-input placeholder="End Date" style="background:#eee" type="date" v-model="edate"></b-form-input>
                <b-input-group-append style="border-left:1px solid #CCC">
                    <b-button variant="light" style="background: darkblue !important;font-size:14px;color:#FFF;padding:7px 22px;border-radius:5px" @click="getReport()">{{lang.search}}</b-button>
                </b-input-group-append>
            </b-input-group>
            </v-col>
            <v-col cols="12" md="7" sm="12" class="text-right">
                <b-button variant="light" style="width:145px;margin-right:9px" class="mytab mytab1 mt-3" @click="exportE('e')">{{lang.export_excel}}</b-button>
                <b-button variant="light" style="width:145px;margin-right:3px;background:red;color:#FFF;display:none" class="mytab mt-3" @click="exportE('p')">PDF تصدير</b-button>
            </v-col>
        </v-row>
        <v-row style="directirtlon:rtl">
            <v-col cols="12" md="6" sm="12">
               <v-simple-table>
                    <thead>
                        <tr>
                            <th colspan="5" class="text-center">{{lang.sales_invoices}}</th>
                        </tr>
                        <tr>
                            <th class="text-center">{{lang.inv_date}}</th>
                            <th class="text-center">{{lang.invoiceno}}</th>
                            <th class="text-center">{{lang.total}}</th>
                            <th class="text-center">{{lang.vat}}</th>
                            <th class="text-center">{{lang.ftotal}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,ind) in invRows" :key="ind">
                            <td class="text-center">{{ item.inv_date.substr(0, 10) }}</td>
                            <td class="text-center">{{ item.invoice_number }}</td>
                            <td :style="item.fontcolor" class="text-center">{{ item.total }}</td>
                            <td :style="item.fontcolor" class="text-center">{{ item.vat }}</td>
                            <td :style="item.fontcolor" class="text-center">{{ item.ftotal }}</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr style="background:#f39292">
                            <th class="text-center"></th>
                            <th class="text-center">{{lang.totals}}</th>
                            <th class="text-center">{{ d_inv.total }}</th>
                            <th class="text-center">{{ d_inv.vat }}</th>
                            <th class="text-center">{{ d_inv.ftotal }}</th>
                        </tr>
                    </tfoot>
                </v-simple-table>
            </v-col>
            
            <v-col cols="12" md="6" sm="12">
                <v-simple-table>
                    <thead>
                        <tr>
                            <th colspan="5" class="text-center">{{lang.purchase}}</th>
                        </tr>
                        <tr>
                            <th class="text-center">{{lang.inv_date}}</th>
                            <th class="text-center">{{lang.invocieno}}</th>
                            <th class="text-center">{{lang.total}}</th>
                            <th class="text-center">{{lang.vat}}</th>
                            <th class="text-center">{{lang.ftotal}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,ind) in purRows" :key="ind">
                            <td class="text-center">{{ item.bill_date.substr(0, 10) }}</td>
                            <td class="text-center">{{ item.bill_number }}</td>
                            <td class="text-center">{{ item.total }}</td>
                            <td class="text-center">{{ item.vat }}</td>
                            <td class="text-center">{{ item.ftotal }}</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr style="background:#92f395">
                            <th class="text-center"></th>
                            <th class="text-center">{{lang.totals}}</th>
                            <th class="text-center">{{ d_pur.total }}</th>
                            <th class="text-center">{{ d_pur.vat }}</th>
                            <th class="text-center">{{ d_pur.ftotal }}</th>
                        </tr>
                    </tfoot>
                </v-simple-table>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="4" sm="12">
                <v-simple-table>
                    <thead>
                        <tr>
                            <th class="text-center" colspan="3">{{lang.sales}}</th>
                        </tr>
                        <tr>
                            <th class="text-center">{{lang.toal}}</th>
                            <th class="text-center">{{lang.vat}} 15%</th>
                            <th class="text-center">{{lang.ftotal}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="text-center">{{inall_totoals}}</td>
                            <td class="text-center">{{inall_vats}}</td>
                            <td class="text-center">{{inall_ftotoals}}</td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </v-col>
            
            <v-col cols="12" md="4" sm="12">
                <v-simple-table>
                    <thead>
                        <tr>
                            <th class="text-center" colspan="3">{{lang.purchase}}</th>
                        </tr>
                        <tr>
                            <th class="text-center">{{lang.total}}</th>
                            <th class="text-center">{{lang.vat}} 15%</th>
                            <th class="text-center">{{lang.ftotal}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="text-center">{{outall_totoals}}</td>
                            <td class="text-center">{{outall_vats}}</td>
                            <td class="text-center">{{outall_ftotoals}}</td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </v-col>

            <v-col cols="12" md="4" sm="12">
                <v-simple-table>
                    <thead>
                        <tr>
                            <th class="text-center" colspan="3">{{lang.vat_required_return}}</th>
                        </tr>
                        <tr>
                            <th class="text-center">{{lang.total}}</th>
                            <th class="text-center">{{lang.vat}} 15%</th>
                            <th class="text-center">{{lang.ftotal}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="text-center">{{sub_totals}}</td>
                            <td class="text-center">{{sub_vats}}</td>
                            <td class="text-center">{{sub_ftotoals}}</td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </v-col>
        </v-row>

    </div>
        <Footer />
    </div>
</template>

<script>
import axios from 'axios';
import BreadCrumbs from '@/components/breadCrumbs.vue';
import TabsComp from '@/components/tabsComp.vue';
import HeaderPortrate from '@/components/Header-Portrate.vue';
import Footer from '@/components/Footer.vue';
export default{
    components: {BreadCrumbs, TabsComp, HeaderPortrate, Footer},
    data() {
        return {
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            date1: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            sdate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            edate: '',
            modal: false,
            modal1: false,
            active_tab:3,
            
            incomeRows: [],
            outRows: [],
            purRows: [],
            invRows: [],
            d_out:{
                total: 0,
                vat: 0,
                ftotal: 0,
            },
            d_in:{
                total: 0,
                vat: 0,
                ftotal: 0,
            },
            d_pur:{
                total: 0,
                vat: 0,
                ftotal: 0,
            },
            d_inv:{
                total: 0,
                vat: 0,
                ftotal: 0,
            },
            inall_totoal: 0,
            inall_vat: 0,
            inall_ftotoal: 0,
            outall_totoal: 0,
            outall_vat: 0,
            outall_ftotoal: 0,
            sub_total: 0,
            sub_vat: 0,
            sub_ftotoal: 0,
        }
    },
    computed: {
        inall_totoals: function(){
            let t = 0;
            for(let i=0;i<this.invRows.length;i++){
                t = parseFloat(t) + parseFloat(this.invRows[i].total);
            }
            return this.$RoundNum(t);
        },
        inall_vats: function(){
            let t = 0;
            for(let i=0;i<this.invRows.length;i++){
                t = parseFloat(t) + parseFloat(this.invRows[i].vat);
            }
            return this.$RoundNum(t);
        },
        inall_ftotoals: function(){
            let t = 0;
            for(let i=0;i<this.invRows.length;i++){
                t = parseFloat(t) + parseFloat(this.invRows[i].ftotal);
            }
            return this.$RoundNum(t);
        },
        outall_totoals: function(){
            let t = 0;
            for(let i=0;i<this.purRows.length;i++){
                t = parseFloat(t) + parseFloat(this.purRows[i].total);
            }
            return this.$RoundNum(t);
        },
        outall_vats: function(){
            let t = 0;
            for(let i=0;i<this.purRows.length;i++){
                t = parseFloat(t) + parseFloat(this.purRows[i].vat);
            }
            return this.$RoundNum(t);
        },
        outall_ftotoals: function(){
            let t = 0;
            for(let i=0;i<this.purRows.length;i++){
                t = parseFloat(t) + parseFloat(this.purRows[i].ftotal);
            }
            return this.$RoundNum(t);
        },
        sub_totals: function(){
            return this.$RoundNum(parseFloat(this.inall_totoals) - parseFloat(this.outall_totoals))
        },
        sub_vats: function(){
            return this.$RoundNum(parseFloat(this.inall_vats) - parseFloat(this.outall_vats))
        },
        sub_ftotoals: function(){
            return this.$RoundNum(parseFloat(this.inall_ftotoals) - parseFloat(this.outall_ftotoals))
        },
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        items: function(){
            return {
                    text: this.lang.vat_report,
                    disabled: true,
                    to: '/reports/vat-reports',
                }
        },
        groupid: function() {
            return this.$store.state.group_id;
        },
        tabs: function() {
            let tabs =  [
                {
                    index:3,name:this.lang.vat_report,href:'/reports/vat-reports',class:'mytab',smclass:'font-small'
                },
               
            ];
            if(this.groupid != 3 && !this.$store.state.licenseType.onlySalesPurchase) {
              tabs.push(
                  {
                    index: 4,
                    name: this.lang.income_report,
                    href: '/reports/income-report',
                    class: 'mytab3',
                    smclass: 'font-small'
                  }
              );
              tabs.push(
                  {
                    index: 7,
                    name: this.lang.account_statement,
                    href: '/reports/statment-report',
                    class: 'mytab3',
                    smclass: 'font-small'
                  }
              );
              tabs.push(
                  {
                    index: 8,
                    name: this.lang.account_balance_report,
                    href: '/reports/balance-report',
                    class: 'mytab3',
                    smclass: 'font-small'
                  }
              );
              tabs.push(
                  {
                    index: 8,
                    name: this.lang.bnl_report,
                    href: '/reports/bnl-report',
                    class: 'mytab3',
                    smclass: 'font-small'
                  }
              );
              tabs.push(
                  {
                    index: 8,
                    name: this.lang.balance_report,
                    href: '/reports/acbalance-report',
                    class: 'mytab3',
                    smclass: 'font-small'
                  }
              );
              tabs.push(
                  {
                    index: 2,
                    name: this.lang.sales_report,
                    href: '/reports/sales-reports',
                    class: 'mytab3',
                    smclass: 'font-small'
                  }
              );

              tabs.push({
                    index: 5,
                    name: this.lang.return_report,
                    href: '/reports/return-reports',
                    class: 'mytab6',
                    smclass: 'font-small'
                  }
              );
            }
            if(this.groupid != 3){
                tabs.push({
                        index:0,name:this.lang.received_voucher,href:'/finance/inreceipt',class: 'mytab4',smclass:'font-small'
                    }
                );
                tabs.push({
                        index:1,name:this.lang.exchange_voucher,href:'/finance/outreceipt',class: 'mytab5',smclass:'font-small'
                    }
                );
            }
            return tabs;
        }
    },
    methods: {
        exportE(type){
            const post = new FormData();
            post.append("export","vatreport");
            post.append("type",type);
            post.append('sdate',this.sdate);
            post.append('edate',this.edate);
            axios.post(
                this.$store.state.SAMCOTEC.e_path , post
            ).then((response) => {
                const res = response.data;
                window.open('../api/'+res.url,'_blank');
            })
        },
        calcTotal(){
            const d_out = this.outRows;
            const d_in = this.incomeRows;
            const d_pur = this.purRows;
            const d_inv = this.invRows;
            this.d_out = {
                total: 0,
                vat: 0,
                ftotal: 0,
            }
            this.d_in = {
                total: 0,
                vat: 0,
                ftotal: 0,
            } 
            this.d_pur = {
                total: 0,
                vat: 0,
                ftotal: 0,
            }
            this.d_inv = {
                total: 0,
                vat: 0,
                ftotal: 0,
            }

            for(var item in d_out){
                this.d_out.total = +this.d_out.total + +d_out[item].total;
                this.d_out.vat = +this.d_out.vat + +d_out[item].vat;
                this.d_out.ftotal = +this.d_out.ftotal + +d_out[item].ftotal;
            }

            for(item in d_in){
                this.d_in.total = +this.d_in.total + +d_in[item].total;
                this.d_in.vat = +this.d_in.vat + +d_in[item].vat;
                this.d_in.ftotal = +this.d_in.ftotal + +d_in[item].ftotal;
            }

            for(item in d_pur){
                this.d_pur.total = +this.d_pur.total + +d_pur[item].total;
                this.d_pur.vat = +this.d_pur.vat + +d_pur[item].vat;
                this.d_pur.ftotal = +this.d_pur.ftotal + +d_pur[item].ftotal;
            }

            for(item in d_inv){
                this.d_inv.total = +this.d_inv.total + +d_inv[item].total;
                this.d_inv.vat = +this.d_inv.vat + +d_inv[item].vat;
                this.d_inv.ftotal = +this.d_inv.ftotal + +d_inv[item].ftotal;
            }
            this.d_out.total = this.$RoundNum(this.d_out.total);
            this.d_out.vat = this.$RoundNum(this.d_out.vat);
            this.d_out.ftota = this.$RoundNum(this.d_out.ftota);
            this.d_in.total = this.$RoundNum(this.d_in.total);
            this.d_in.vat = this.$RoundNum(this.d_in.vat);
            this.d_in.ftotal = this.$RoundNum(this.d_in.ftotal);
            this.d_pur.total = this.$RoundNum(this.d_pur.total);
            this.d_pur.vat = this.$RoundNum(this.d_pur.vat);
            this.d_pur.ftotal = this.$RoundNum(this.d_pur.ftotal);
            this.d_inv.total = this.$RoundNum(this.d_inv.total);
            this.d_inv.vat = this.$RoundNum(this.d_inv.vat);
            this.d_inv.ftotal = this.$RoundNum(this.d_inv.ftotal);

            this.inall_totoal = +this.d_in.total + +this.d_inv.total;
            this.inall_vat = +this.d_in.vat + +this.d_inv.vat;
            this.inall_ftotoal = +this.d_in.ftotal + +this.d_inv.ftotal;

            this.outall_totoal = +this.d_out.total + +this.d_pur.total;
            this.outall_vat = +this.d_out.vat + +this.d_pur.vat;
            this.outall_ftotoal = +this.d_out.ftotal + +this.d_pur.ftotal;

            this.outall_totoal = this.$RoundNum(this.outall_totoal);
            this.outall_vat = this.$RoundNum(this.outall_vat);
            this.outall_ftotoal = this.$RoundNum(this.outall_ftotoal);

            this.sub_total = +this.inall_totoal - +this.outall_totoal;
            this.sub_vat =  +this.inall_vat - +this.outall_vat;
            this.sub_ftotoal =  +this.inall_vat - +this.outall_vat;

            this.sub_total = this.$RoundNum(this.sub_total);
            this.sub_vat = this.$RoundNum(this.sub_vat);
            this.sub_ftotoal = this.$RoundNum(this.sub_ftotoal);
        },
        getReport(){
            const post = new FormData();
            post.append("type" , 'getVatReport');
            post.append("auth" , this.$cookies.get(this.$COOKIEPhase));
            post.append("data[sdate]" , this.sdate);
            post.append("data[edate]" , this.edate);

            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                console.log(res);
                this.incomeRows = res.results.data.inres;
                this.outRows = res.results.data.outres;
                this.purRows = res.results.data.purres;
                this.invRows = res.results.data.invRows;
            }).then(() => {this.calcTotal()})
        }
    },
    created() {
        this.getReport();
    },
}
</script>